import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { SearchContext } from "./searchcontext"

import { SearchTitle, SuggestedSearchButton } from "./search.styles"

import { matchAlgoliaToContentful } from "./utils"
import ResultCard from "./resultcard"
import LinkedItems from "./linkitems"
import QuizCTA from "./quizcta"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"

/**
 *
 * @param {[]} products
 * @param {[]} articles
 * @param {function} onClick
 * @returns {Element}
 * @constructor
 */
const SuggestedSearch = ({ products, articles, pages, onClick }) => {
  const { search, searchState, setSearchState, contentfulProducts } =
    useContext(SearchContext)
  const [isFaqExpanded, setIsFaqExpanded] = useState(false)

  const matchedProducts = matchAlgoliaToContentful(
    contentfulProducts,
    products?.hits
  )
  const isProductResultsFound = Boolean(matchedProducts.length)

  useEffect(() => {
    setSearchState("results")
    setIsFaqExpanded(false)
  }, [search, setSearchState])

  return (
    <div className={"suggested-search"}>
      {Boolean(articles?.length) && (
        <div className="mb-8 sm:mb-[40px] md:mb-[80px]">
          <LinkedItems
            title={"Frequently Asked Questions"}
            items={isFaqExpanded ? articles : articles.slice(0, 3)}
            onClick={onClick}
          />
          {articles?.length > 3 && !isFaqExpanded ? (
            <ButtonWild
              textLink
              label="View More"
              onClick={() => setIsFaqExpanded(true)}
              className="block view-more-faq-button mt-2 sm:mt-4"
            />
          ) : null}
        </div>
      )}
      {isProductResultsFound && (
        <>
          <SearchTitle text={"Products"} />
          <div className={"flex gap-2 lg:gap-4 flex-wrap mb-8 sm:mb-0"}>
            {matchedProducts.map((product) => {
              if (product) {
                return (
                  <SuggestedSearchButton
                    key={product?.sku}
                    className={"h-auto"}
                    onClick={(event) => {
                      segmentEvent("Search Clicked", {
                        name: "Search",
                        location: "navigation",
                        state: searchState,
                        text: product?.title,
                        type: "Link",
                        // Algolia Properties
                        index: "Products",
                        eventType: "click",
                        queryID: products?.queryID,
                        objectID: product?.objectID,
                      })
                      onClick(event, product)
                    }}
                  >
                    <ResultCard
                      data={product}
                      onClick={onClick}
                      isSearchResult={true}
                    />
                  </SuggestedSearchButton>
                )
              } else {
                return null
              }
            })}
          </div>
        </>
      )}
      <div
        className={`mb-0 sm:mb-[40px] ${
          isProductResultsFound ? "md:mb-[80px]" : ""
        }`}
      >
        <QuizCTA />
      </div>
      {Boolean(pages?.length) ? (
        <LinkedItems
          title={"Resources"}
          items={pages}
          onClick={(e, item) => navigate(item.path)}
        />
      ) : null}
    </div>
  )
}

export default SuggestedSearch
