import instagram from "../../../../assets/icons/nova/social-instagram.svg"
import tiktok from "../../../../assets/icons/nova/social-tiktok.svg"
import facebook from "../../../../assets/icons/nova/social-facebook.svg"
import linkedin from "../../../../assets/icons/nova/social-linkedin.svg"

const mageURL = process.env.GATSBY_MAGENTO_URL || ""

export const preFooterData = {
  copy: {
    text:
    "*Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
  },
}

export const data = {
  copy: {
    text:
      "© " +
      new Date().getFullYear() +
      " Nutraceutical Wellness Inc. All Rights Reserved. These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
  },
  keep: {
    title: "The #1 dermatologist-recommended hair growth supplement brand.",
    text: "Nutrafol® is built on a science-backed approach to whole-body wellness and hair health. Our products are clinically tested and physician-formulated to support different bio-specific needs during various life stages and lifestyles so that you can grow into the best version of yourself.",
  },
  patent: {
    title: "Our Patent",
    text: "Nutrafol® is a registered trademark of Nutraceutical Wellness, Inc. Synergen Complex® is a registered trademark of Nutraceutical Wellness Inc. <br/>U.S. Patent Nos. 11,213,478, 10,709,659 and 10,688,037.",
  },
  email: {
    title: "Stay in the thick of it with our newsletter.",
    titleDesktop: "Stay in the thick of it.",
    text: "Email address",
    disclaimer: "Get offers and updates sent directly to your inbox.",
  },
  mobile: [
    {
      title: "FAQ",
      link: "/faq/",
    },
    {
      title: "CONTACT",
      link: "/contact-us/",
    },
    {
      title: "PRESS",
      link: "/press/",
    },
  ],
  wellness: [
    {
      title: "Shop Products",
      link: "/products/",
    },
    {
      title: "The Science",
      link: "/science/",
    },
    {
      title: "Ingredients",
      link: "/ingredients/",
    },
    {
      title: "Results",
      link: "/results/",
    },
    {
      title: "Find a Provider",
      link: "/partners/",
    },
    {
      title: "Blog",
      link: mageURL + "blog/",
    },
    {
      title: "FAQ",
      link: "/faq/",
    },
  ],
  company: [
    {
      title: "Contact",
      link: "/contact-us/",
    },
    {
      title: "Press",
      link: "/press/",
    },
    {
      title: "Careers",
      link: "https://sites.google.com/nutrafol.com/careers/careers",
    },
    {
      title: "Our Story",
      link: "/story/",
    },
    {
      title: "Our Service",
      link: "/service/",
    },
    {
      title: "Shed the Silence",
      link: "/shedthesilence/",
    },
    {
      title: "Become a Partner",
      link: "/professionals/",
    },
    {
      title: "Social Purpose",
      link: "/social-purpose/",
    },
  ],
  policy: [
    {
      title: "Terms of Use",
      link: "/terms-of-use/",
    },
    {
      title: "Privacy Policy",
      link: "/privacy-policy/",
    },
    {
      title: "Consumer Health Data Privacy Policy",
      link: "/consumer-health-data-privacy-policy/",
    },
    {
      title: "AdChoices - Do Not Sell or Share",
      link: "adchoices",
    },
    {
      title: "Do Not Sell or Share My Personal Information",
      link: "/privacy-request/",
    },
    {
      title: "Limit Use of My Sensitive Personal Information",
      link: "/privacy-request/?preselected=limit",
    },
    {
      title: "Sitemap",
      link: mageURL + "sitemap-0.xml",
    },
  ],
  form: {
    text: "Sign up to our mailing list and get $10 off your first subscription order.",
    placeholder: "Your Email Address",
  },
  social: [
    {
      title: "instagram",
      img: instagram,
      link: "https://www.instagram.com/nutrafol/",
    },
    {
      title: "tiktok",
      img: tiktok,
      link: "https://www.tiktok.com/@nutrafol",
    },
    {
      title: "facebook",
      img: facebook,
      link: "https://www.facebook.com/nutrafol/",
    },
    {
      title: "linkedin",
      img: linkedin,
      link: "https://www.linkedin.com/company/nutrafol/",
    },
  ],
}

export const altData = {
  copy: {
    title:
      "© " +
      new Date().getFullYear() +
      " Nutraceutical Wellness Inc. <br>All Rights Reserved.  ",
    text: "These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease.",
  },
  social: [
    {
      title: "instagram",
      img: instagram,
      link: "https://www.instagram.com/nutrafol/",
    },
    {
      title: "tiktok",
      img: tiktok,
      link: "https://www.tiktok.com/@nutrafol",
    },
    {
      title: "facebook",
      img: facebook,
      link: "https://www.facebook.com/nutrafol/",
    },
    {
      title: "linkedin",
      img: linkedin,
      link: "https://www.linkedin.com/company/nutrafol/",
    },
  ],
}
