import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

/**
 * Primary UI link for user interaction
 */
export const Linked = ({ href, className, size, label='', preventDefault, title, target, children, onClick, trackObject, ...props }) => {

  let trackFn = () => {}
  const isBlank = target === '_blank'
  //const _title = title || (isBlank ? "Opens in a new tab" : "Opens in the same tab");

  if(trackObject){
    trackObject.name = trackObject.name || (isBlank ? "Outbound Link Clicked" : "Marketing CTA Clicked")
    const obj  = {type: 'link', ...trackObject}
    obj.text = trackObject.text || label.replace(/(<([^>]+)>)/gi, "")
    obj.destination = trackObject.destination || href
    trackFn = () => {
      segmentEvent(trackObject.name, obj)
    }
  }

  const onClickNew = function(e) {
    if(typeof onClick === 'function'){
      onClick.apply(this, arguments)
    }
    if (typeof preventDefault !== 'undefined') {
      e.preventDefault()
    }
    trackFn()
  }

  if (href?.indexOf("http") !== -1) {
    return (
        <a  onClick={onClickNew}
            className={`link ${className} ${size}`}
            href={href}
            target={target || undefined}
            {...props}
        >{children || label}</a>
    )
  }

  return  <Link onClick={onClickNew}
                to={href}
                className={`link ${className} ${size}`}
                {...props}
          >{children || label}</Link>

}

Linked.propTypes = {
  /**
   * What className to use (green-link etc)
   */
  className: PropTypes.string,
  /**
   * How large should the Link be?
   */
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  /**
   * Link contents
   */
  label: PropTypes.string,
  /**
   * Link href
   */
  href: PropTypes.string.isRequired,
  /**
   * Link title
   */
  title: PropTypes.string,
  /**
   * preventDefault - pass any value to disable click following
   */
  preventDefault: PropTypes.string,
  /**
   * Link target
   */
  target: PropTypes.string,
  /**
   * Segments trackObject object e.g.   `{name: "Marketing CTA Clicked"}`
   * ///////////////////
   * `destination` is populated from `Link href`
   */
  trackObject: PropTypes.object,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
}

Linked.defaultProps = {
  href: '#',
  size: 'medium'
}
