import styled from "@emotion/styled"
import theme from "../styles/theme"

export const WildButtonNova = styled.button`
  display: flex;
  justify-content: center;

  .left {
    margin-right: 12px;

    @media (min-width: 1280px) {
      margin-right: 16px;
    }

    &.Tertiary {
      margin-right: 8px;

      @media (min-width: 1280px) {
        margin-right: 10px;
      }
    }

    &.Textlink {
      margin-right: 4px;
    }
  }

  .right {
    margin-left: 12px;

    @media (min-width: 1280px) {
      margin-left: 16px;
    }

    &.Tertiary {
      margin-left: 8px;

      @media (min-width: 1280px) {
        margin-left: 10px;
      }
    }

    &.Textlink {
      margin-left: 4px;
    }
  }

  .label {
    line-height: 24px !important;
    //transform: translate(0, 1px);
  }

  &.Small {
    .label {
      line-height: 11px !important;
    }
  }

  &.Primary {
    padding: 12px 12px;
    @media (min-width: 1280px) {
      padding: 16px 16px;
    }

    &.Noicon {
      padding: 12px 18px;
      @media (min-width: 1280px) {
        padding: 16px 22px;
      }
    }

    .label {
      color: ${theme.wild.color.nova.base.white};
    }

    &.loading {
      .label {
        color: ${theme.wild.color.nova.transparency.white[60]};
      }
    }
  }

  &.loading {
    .label {
      color: ${theme.wild.color.nova.transparency.black[60]};
    }

    &.Textlink {
      .label {
        color: ${theme.wild.color.nova.brand.primary[80]};
      }
    }
  }

  &.disabled {
    .label {
      color: ${theme.wild.color.nova.transparency.black[40]};
    }
  }

  &.Textlink {
    * {
      color: ${theme.wild.color.nova.brand.primary[80]};
    }

    border-bottom: 1px solid ${theme.wild.color.nova.brand.primary[80]};

    &.hover,
    &:hover {
      * {
        color: ${theme.wild.color.nova.brand.primary[100]};
      }

      .arrow {
        fill: ${theme.wild.color.nova.brand.primary[100]};
      }

      border-bottom: 1px solid ${theme.wild.color.nova.brand.primary[100]};
    }

    &.active,
    &:active {
      * {
        color: ${theme.wild.color.nova.base.black};
      }

      .arrow {
        fill: ${theme.wild.color.nova.base.black};
      }

      border-bottom: 1px solid ${theme.wild.color.nova.base.black};
    }

    &.Only {
      border-bottom: none;
    }
  }

  &:not(.Textlink) {
    border-radius: 4px;
    transition: all ease-in-out 0.05s;

    text-align: center;

    //default level
    color: ${theme.wild.color.nova.base.white};
    background-color: ${theme.wild.color.nova.brand.primary[80]};

    &.hover,
    &.active,
    &:hover,
    &:active {
      background-color: ${theme.wild.color.nova.brand.primary[100]};

      .bg {
        fill: ${theme.wild.color.nova.brand.primary[80]};
      }
    }

    &.active,
    &:active {
      background-color: #1a403d;
    }

    &.disabled {
      background-color: ${theme.wild.color.nova.transparency.black[40]};
    }
  }

  //Secondary level

  &.Secondary {
    padding: 12px 12px;
    @media (min-width: 1280px) {
      padding: 16px 16px;
    }

    &.Noicon {
      padding: 12px 18px;
      @media (min-width: 1280px) {
        padding: 16px 22px;
      }
    }

    color: ${theme.wild.color.nova.base.black};
    background-color: ${theme.wild.color.nova.base.solid[20]};
    text-align: center;

    .arrow {
      fill: ${theme.wild.color.nova.base.black};
    }

    &.hover,
    &.active,
    &:hover,
    &:active {
      background-color: ${theme.wild.color.nova.base.solid[40]};

      .bg {
        fill: ${theme.wild.color.nova.transparency.black[20]};
      }
    }

    &:active,
    &.active {
      background-color: ${theme.wild.color.nova.transparency.black[20]};

      .bg {
        fill: #909a9b;
      }
    }

    &.disabled {
      background-color: ${theme.wild.color.nova.transparency.black[40]};
    }
  }

  //Tertiary level

  &.Tertiary {
    color: ${theme.wild.color.nova.base.black};
    background-color: transparent;
    text-align: center;

    .arrow {
      fill: ${theme.wild.color.nova.base.black};
    }

    &.hover,
    &:hover {
      background-color: transparent;

      .bg {
        fill: ${theme.wild.color.nova.brand.accent[100]};
      }
    }

    &:active,
    &.active {
      background-color: transparent;

      .bg {
        fill: ${theme.wild.color.nova.base.black};
      }

      .arrow {
        fill: ${theme.wild.color.nova.base.white};
      }
    }

    &.disabled {
      background-color: transparent;

      .arrow {
        fill: ${theme.wild.color.nova.transparency.black[40]};
      }
    }
  }
`

export const ArrowWrap = styled.span`
  display: inline-block;
  //transform: translateY(1px);

  &.Primary {
    .bg {
      fill: ${theme.wild.color.nova.brand.primary[100]};
    }

    .arrow {
      fill: #fff;
    }

    &.disabled {
      .bg {
        fill: ${theme.wild.color.nova.transparency.black[20]};
      }

      .arrow {
        fill: ${theme.wild.color.nova.transparency.white[60]};
      }
    }
  }

  &.Secondary {
    .bg {
      fill: ${theme.wild.color.nova.transparency.black[10]};
    }

    &.disabled {
      .bg {
        fill: ${theme.wild.color.nova.transparency.black[20]};
      }

      .arrow {
        fill: ${theme.wild.color.nova.transparency.white[60]};
      }
    }
  }

  &.Tertiary {
    .bg {
      fill: ${theme.wild.color.nova.brand.accent[80]};
    }

    .arrow {
      fill: ${theme.wild.color.nova.base.black};
    }

    &.disabled {
      .bg {
        fill: ${theme.wild.color.nova.transparency.black[20]};
      }

      .arrow {
        fill: ${theme.wild.color.nova.transparency.black[60]};
      }
    }
  }

  &.Textlink {
    width: 14px;
    height: 8px;
    transform: translate(0, 5px);

    .bg {
      fill: ${theme.wild.color.nova.base.white};
    }

    .arrow {
      fill: ${theme.wild.color.nova.brand.primary[80]};
    }

    &.disabled {
      .bg {
        fill: ${theme.wild.color.nova.base.white};
      }

      .arrow {
        fill: ${theme.wild.color.nova.transparency.black[40]};
      }
    }
  }
`

export const LoadingWrap = styled.span`
  display: flex;
  width: 24px;
  height: 24px;
  background-color: ${theme.wild.color.nova.transparency.black[20]};
  border-radius: 4px;
  justify-content: center;
  align-items: center;

  svg {
    display: block;
    animation: loadingSpinner 0.75s infinite linear;
    //transform: translate(2px, 2px);
  }

  &.Secondary {
    background-color: #d6dcdf;
  }

  &.Only {
    margin: 0px 8px;
    background: none;

    &.Small {
      height: 11px;
    }
  }

  &.Tertiary {
    background-color: ${theme.wild.color.nova.brand.accent[80]};

    &.Only {
      background-color: ${theme.wild.color.nova.brand.accent[80]};
    }
  }

  &.Textlink {
    width: 14px;
    height: 12px;
    background-color: ${theme.wild.color.nova.base.white};
    transform: translateY(6px);
  }
`

export const ChildrenWrapper = styled.div`
  display: inline-block;
`
