import React, { useState } from "react"
import { graphql } from "react-apollo"
import { Link } from "gatsby"

import UserContext from "../../../../context/UserContext"
import { data } from "./data"

import ArrowIconPrimary from "../../../../../src/assets/icons/nova/arrow-right.svg"
import Logo from "../../../../assets/icons/wild/logo-icon.svg"

import {
  identifyEvent,
  segmentEvent,
} from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import { ButtonNova } from "../../../../../nutrafol-ui-kit/src/ButtonNova.js"

import { emailSubscribeMutation } from "../../../../queries/index"
import { ScreenReader } from "../../../../utils/screenReader"
import { isEmailValid } from "../../../../utils/general"
import AdChoicesLink from "../../global/adchoiceslink"
import AdChoicesWrap from "../../global/adchoiceslink/adchoiceswrap"

import {
  MainBox,
  DesktopWrapper,
  EmailError,
  FooterContainer,
  FooterWrap,
  MobileWrapper,
  StyledCopyright,
  StyledCopyrightWrapper,
  StyledLeftSide,
  StyledLinks,
  StyledLinksSection,
  StyledRightSide,
  StyledTerms,
  FormEmailAddress,
  LogoWrap,
  StyledMobileLinks,
  TabletEmailForm,
  LoginBox,
  SignUpTerms,
} from "./footer.styled.js"

const ctaClicked = (e, dest) => {
  const target = e?.target
  segmentEvent("Marketing CTA Clicked", {
    destination: dest ? dest : target?.href,
    text: target?.innerText,
    module_location: "women_footer",
    type: "link",
  })
}

const LinkList = ({ list }) => {
  const handleCtaClick = (el) => {
    segmentEvent("Marketing CTA Clicked", {
      destination: el.link.split("?")[0],
      module_location: "women_footer",
      text: el.title,
      type: "link",
    })
  }

  const linkList = list.map((el, idx) => {
    if (el.link === "adchoices") {
      const cls = process.env.GATSBY_RENDER_EVIDON === "1" ? "block" : "hidden"
      return (
        <div className={`link ${cls}`} key={`${el.link}-${idx}`}>
          <AdChoicesLink />
        </div>
      )
    }
    return (
      <div
        className={`link ${el.title.toLowerCase()}`}
        key={`${el.link}-${idx}`}
      >
        {el.link.indexOf("http") === 0 ? (
          <a
            href={el.link}
            onClick={() => handleCtaClick(el)}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={`${el.title} (Opens in new tab)`}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </a>
        ) : (
          <Link
            to={el.link}
            onClick={() => handleCtaClick(el)}
            state={el?.state ? el.state : {}}
          >
            <TextElementNova
              element="span"
              className="Body-10-Dia-Normal"
              text={el.title}
            />
          </Link>
        )}
      </div>
    )
  })
  return linkList
}

const MESSAGES = {
  SUCCESS: "Thanks for signing up!",
  ERROR: "Something went wrong. ",
  ERROR2:
    "Could not connect to the registration server. Please try again later.",
  ERROR3: "There was a problem with the subscription: ",
}

const NovaFooter = (props) => {
  const [emailError, changeEmailError] = useState("")
  const [inputValue, changeInputValue] = useState("")
  const [emailSent, changeEmailSent] = useState(false)
  const [subscribeInProgress, setSubscribeInProgress] = useState(false)

  const handleInputChange = (e) => {
    changeInputValue(e.target.value)
  }

  // function to handle submit of email input in form/button
  const handleEmailSubmit = (e) => {
    e.preventDefault()
    if (subscribeInProgress) {
      return
    }
    if (inputValue === "") {
      return changeEmailError("This is a required field.")
    }
    if (!isEmailValid(inputValue)) {
      return changeEmailError(
        "Please enter a valid email address (Ex: johndoe@domain.com)."
      )
    }
    setSubscribeInProgress(true)
    changeEmailSent(true)

    props
      .mutate({
        variables: {
          email: inputValue,
        },
      })
      .then((res) => {
        const status = res.data.subscribeForNewsletters.result.status
        let content = res.data.subscribeForNewsletters.result.message
        if (parseInt(status) === 403) {
          changeEmailError(MESSAGES.ERROR3 + content)
          ScreenReader.speak(MESSAGES.ERROR3)
        } else if (parseInt(status) === 200) {
          changeEmailError("")
          ScreenReader.speak(MESSAGES.SUCCESS)
          segmentEvent("Email Marketing Opted In", {
            acquisition_source: "footer",
            date_opted_in: new Date().toISOString(),
            email: inputValue,
          })

          identifyEvent({
            email: inputValue,
            email_marketing_opted_in: true,
          })
        } else {
          changeEmailError(MESSAGES.ERROR + MESSAGES.ERROR2)
          ScreenReader.speak(MESSAGES.ERROR3)
        }
        setSubscribeInProgress(false)
        return res
      })
      .then((json) => json)
      .catch(() => {
        changeEmailError(MESSAGES.ERROR + MESSAGES.ERROR2)
        ScreenReader.speak(MESSAGES.ERROR3)
        setSubscribeInProgress(false)
      })
    changeEmailError("")
  }

  const handleSocialClick = (el) => {
    segmentEvent("Outbound Link Clicked", {
      destination: el.link,
      module_location: "women_footer",
      text: "",
      type: "icon",
    })
  }

  const LoginArea = ({ type }) => (
    <UserContext.Consumer>
      {(userState) => (
        <>
          {!userState.isLoggedIn ? (
            <LoginBox className={type}>
              <TextElementNova
                text="Already a customer?"
                element="p"
                className="Body-20-Dia-Strong"
              />

              <ButtonNova
                label="Log in to your myNutrafol account"
                level="Textlink"
                onClick={(e) => ctaClicked(e, "/customer/account/login/")}
                ctaLink={"/customer/account/login/"}
              />
            </LoginBox>
          ) : (
            <></>
          )}
        </>
      )}
    </UserContext.Consumer>
  )

  const buildEmailForm = (type) => {
    return (
      <>
        <FormEmailAddress onSubmit={handleEmailSubmit} className={type}>
          <TextElementNova
            className="Body-10-Dia-Normal"
            text={data.form.text}
          />

          {!subscribeInProgress && emailSent && !emailError ? (
            <TextElementNova
              className="Body-10-Dia-Strong"
              text={MESSAGES.SUCCESS}
            />
          ) : (
            <div className="form-group">
              <input
                type="text"
                name="email"
                onChange={handleInputChange}
                value={inputValue}
                className="form-email"
                placeholder={data.form.placeholder}
              />
              <button type="submit" className="form-submit">
                <ArrowIconPrimary />
              </button>
            </div>
          )}

          {emailError && (
            <EmailError>
              <TextElementNova
                className="Caption-10-Dia-Normal"
                text={emailError}
              />
            </EmailError>
          )}

          <SignUpTerms>
            <TextElementNova
              text="By signing up, you agree to our "
              element="span"
            />

            <Link
              onClick={(e) => ctaClicked(e, "/signupsave-terms/")}
              to="/signupsave-terms/"
            >
              Sign-Up and Save Policy
            </Link>

            <TextElementNova text=" and our  " element="span" />

            <Link
              onClick={(e) => ctaClicked(e, "/privacy-policy/")}
              to="/privacy-policy/"
            >
              Privacy Policy
            </Link>

            <TextElementNova text="." element="span" />
          </SignUpTerms>
        </FormEmailAddress>

        <LoginArea type={type} />
      </>
    )
  }

  return (
    <FooterContainer data-mousedown>
      <MainBox>
        <MobileWrapper>
          <StyledLeftSide>
            <LogoWrap>
              <Logo />
            </LogoWrap>

            <section className="keep">
              <TextElementNova
                element="h2"
                className="Body-10-Dia-Strong"
                text={data.keep.title}
              />

              <TextElementNova
                className="Body-10-Dia-Normal"
                text={data.keep.text}
              />
            </section>

            <section className="patent">
              <TextElementNova
                element="h2"
                className="Body-10-Dia-Strong"
                text={data.patent.title}
              />
              <TextElementNova
                className="Body-10-Dia-Normal patent"
                text={data.patent.text}
              />
            </section>
          </StyledLeftSide>

          <StyledMobileLinks>
            <StyledLinks className="col-wellness">
              <TextElementNova
                element="h3"
                className="Caption-10-Dia-Uppercase section-header"
                text="Hair Wellness"
              />
              <LinkList list={data.wellness} />
            </StyledLinks>

            <StyledLinks className="col-company">
              <TextElementNova
                element="h3"
                className="Caption-10-Dia-Uppercase section-header"
                text="Company"
              />
              <LinkList list={data.company} />
            </StyledLinks>

            <StyledLinks className="col-legal-s">
              <TextElementNova
                element="h3"
                className="Caption-10-Dia-Uppercase section-header"
                text="Legal"
              />

              <LinkList list={data.policy} />

              {/*{buildEvidon()}*/}
            </StyledLinks>
          </StyledMobileLinks>

          <StyledMobileLinks>
            <StyledLinks className="col-legal-x">
              <TextElementNova
                element="h3"
                className="Caption-10-Dia-Uppercase section-header"
                text="Legal"
              />

              <LinkList list={data.policy} />
            </StyledLinks>
          </StyledMobileLinks>

          {buildEmailForm("email-m")}

          <StyledCopyright width={1}>
            <TextElementNova
              className="Caption-10-Dia-Normal text"
              text={data.copy.text}
            />
          </StyledCopyright>

          <StyledTerms width={0.73} alignItems="center">
            {data.social.map((el, idx) => {
              const Image = el.img
              return (
                <a
                  href={el.link}
                  key={`${el.link}-${idx}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`visit our ${el.title} page (Opens in new tab)`}
                  onClick={() => handleSocialClick(el)}
                >
                  <Image />
                </a>
              )
            })}
          </StyledTerms>
        </MobileWrapper>

        <DesktopWrapper>
          <FooterWrap className="container">
            <StyledLeftSide>
              <LogoWrap>
                <Logo />
              </LogoWrap>

              <section className="keep">
                <TextElementNova
                  element="h2"
                  className="Body-10-Dia-Strong"
                  text={data.keep.title}
                />

                <TextElementNova
                  className="Body-10-Dia-Normal"
                  text={data.keep.text}
                />
              </section>

              <section className="patent">
                <TextElementNova
                  element="h2"
                  className="Body-10-Dia-Strong"
                  text={data.patent.title}
                />
                <TextElementNova
                  className="Body-10-Dia-Normal"
                  text={data.patent.text}
                />
              </section>

              <TabletEmailForm>{buildEmailForm("email-l")}</TabletEmailForm>
            </StyledLeftSide>

            <StyledRightSide>
              <StyledLinksSection>
                <StyledLinks className="col-wellness">
                  <TextElementNova
                    element="h3"
                    className="Caption-10-Dia-Uppercase section-header"
                    text="Hair Wellness"
                  />
                  <LinkList list={data.wellness} />
                </StyledLinks>

                <StyledLinks className="col-company">
                  <TextElementNova
                    element="h3"
                    className="Caption-10-Dia-Uppercase section-header"
                    text="Company"
                  />
                  <LinkList list={data.company} />
                </StyledLinks>

                <StyledLinks className="col-legal-l">
                  <TextElementNova
                    element="h3"
                    className="Caption-10-Dia-Uppercase section-header"
                    text="Legal"
                  />

                  <div className="link-grid">
                    <LinkList list={data.policy} />
                  </div>
                  {buildEmailForm("email-xl")}
                </StyledLinks>
              </StyledLinksSection>
            </StyledRightSide>

            <StyledCopyrightWrapper>
              <StyledCopyright width={1}>
                <TextElementNova
                  className="Caption-10-Dia-Normal text"
                  text={data.copy.text}
                />
              </StyledCopyright>

              <StyledTerms alignItems="center">
                {data.social.map((el, idx) => {
                  const Image = el.img
                  return (
                    <a
                      href={el.link}
                      key={`${el.link}-${idx}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => handleSocialClick(el)}
                      aria-label={`Visit our ${el.title} page (Opens in new tab)`}
                    >
                      <Image />
                    </a>
                  )
                })}
              </StyledTerms>
            </StyledCopyrightWrapper>
          </FooterWrap>
        </DesktopWrapper>
      </MainBox>

      <AdChoicesWrap />
    </FooterContainer>
  )
}

export default graphql(emailSubscribeMutation)(NovaFooter)
