import styled from "@emotion/styled"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"

const LegalDisclaimerWrapper = styled.div`
  text-align: center;

  p {
    color: ${theme.wild.color.nova.transparency.black[60]};
  }
`

export default LegalDisclaimerWrapper;