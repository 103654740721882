import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import { Flex } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import classNames from "classnames"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"

import { SearchContext } from "../../../search/searchcontext"

import { currentTheme } from "../../../../utils/theming"
import { useIsFirstRender } from "../../../../hooks/useIsFirstRender"

const NavTogglerWrap = styled(Flex)`
  /* theme flash on load https://nutrafol.atlassian.net/browse/NT2-8486 */
  &.theme-none {
    visibility: hidden;
  }

  /* masterbrand theme */
  flex-direction: column;
  width: 70px;

  /* nav theme */
  &.mobile-nav-flex-toggle {
    @media (max-width: 767px) {
      flex-direction: row;
      width: 156px;

      &.theme-mobile-nav-flex-men {
        a.masterbrand {
          background: #fff;
          color: #677574;
        }

        a.men {
          color: #07253a;
          background: #cbe6ef;
          font-weight: bold;
          border: 1px solid red;
        }
      }
    }
  }

  background: #F8F8F5; /* Needs to be added to colors */
  ${queries.medium`
    display: inline-block;
    margin: 4px 0;
    width: 81px;
    border-radius: 4px;
  `}

  a {
    font-size: 12px;
    line-height: 20px;
    padding: 6px 8px;

    ${queries.large`
      font-size: 14px;
    `}

    &.masterbrand {
      color: ${theme.wild.color.nova.base.black};
      background: #ECF1F5;
      box-shadow: 0px 2px 4px 0px rgba(4, 28, 27, 0.08), 0px 6px 10px 0px rgba(4, 28, 27, 0.06), 0px 10px 15px 0px rgba(4, 28, 27, 0.08);
      position: relative;
      z-index: 1;
    }

    &.men {
      color: #435652;

      &:hover {
        color: ${theme.wild.color.nova.base.black};
        background: ${theme.wild.color.nova.base.solid[20]};
      }
    }
  }

  /* mens theme */
  &.theme-men {
    background: rgba(0, 0, 0, 0.1);
    a {
      font-size: 10px;
      line-height: 16px;
      padding: 7px 8px;
      ${queries.medium`
        font-size: 12px;
        padding: 9px;
      `}
      &.masterbrand {
        background: rgba(0, 0, 0, 0.1);
        opacity: 0.55;
        box-shadow: none;
      }
      &.men {
        background: #07253a;
        opacity: 1;
      }
    }
    &.product-page {
      a {
        &.masterbrand {
          background: #f8f8f5;
          opacity: 1;
        }
      }
    }
  }
`
const TogglerLink = styled(Link)`
  display: block;
  text-align: center;
  width: 70px;
  height: 28px;
  letter-spacing: 0.06em;

  &.link-variant-ab {
    box-shadow:
      0px 10px 15px 0px rgba(0, 87, 82, 0.08),
      0px 6px 10px 0px rgba(0, 87, 82, 0.06),
      0px 2px 4px 0px rgba(0, 87, 82, 0.08);
    border-radius: 4px;
  }

  &.mobile-nav-flex-toggle {
    @media (max-width: 767px) {
      width: 78px;
      height: 43px;
      line-height: 32px;
      border-right: 1px #c8d2d1 solid;
      //border-bottom: 1px #C8D2D1 solid;
      &.men {
        background: #fff;
      }
    }
  }
  ${queries.medium`
    border-radius: 4px;
    width: 81px;
    height: 32px;
  `}
`

const NavToggler = ({ isProductPage, classes = ``, themeName = null }) => {
  const { closeSearchDropdown } = useContext(SearchContext)

  const [theme, setTheme] = useState("none")
  const isFirstRender = useIsFirstRender()
  const fontClassesMen =
    theme === "men"
      ? "font-dinamoMono text-master-base-default uppercase"
      : "font-dinamo"
  let fontClassesWomen = fontClassesMen

  if (isProductPage && theme === "men") {
    fontClassesWomen = "font-dinamoMono text-master-base-alt-alt uppercase"
  }

  const map = {
    men: {
      destination: "/men/",
      text: "Men",
      className: "men",
      cls: fontClassesMen,
    },
    women: {
      destination: "/",
      text: "Women",
      className: "masterbrand",
      cls: fontClassesWomen,
    },
  }

  useEffect(() => {
    if (isFirstRender) {
      setTheme(themeName || currentTheme())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const genderSelected = (gender) => {
    segmentEvent("Navigation Clicked", {
      module_location: "navigation",
      destination: map[gender].destination,
      text: map[gender].text + " toggle",
    })
  }

  const renderlink = (gender) => {
    const className = map[gender].className + " " + classes
    return (
      <TogglerLink
        to={map[gender].destination}
        className={classNames(className, {
          //todo remove
          "link-variant-ab": false,
          "!text-[#22202F]": false,
        })}
        onClick={() => genderSelected(gender)}
      >
        <TextElement
          text={map[gender].text}
          element="span"
          className={map[gender].cls}
        />
      </TogglerLink>
    )
  }

  return (
    <NavTogglerWrap
      className={classNames(
        `theme-${theme}`,
        { "product-page": isProductPage },
        classes
      )}
    >
      {renderlink("women")}
      {renderlink("men")}
    </NavTogglerWrap>
  )
}

export default NavToggler
