const novaFonts = {
  "Title-30-Super-Normal": [
    {
      "font-size": "40px",
      "letter-spacing": "-1.2px",
      "line-height": "120%",
      "font-style": "normal",
    },
    {
      "font-size": "60px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-1.8px",
    },
    {
      "font-size": "72px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-2.16px",
    },
  ],
  "Title-30-Super-Italic": [
    {
      "font-size": "40px",
      "letter-spacing": "-1.2px",
      "font-style": "italic",
      "line-height": "120%",
    },
    {
      "font-size": "60px",
      "font-style": "italic",
      "line-height": "120%",
      "letter-spacing": "-1.8px",
    },
    {
      "font-size": "72px",
      "font-style": "italic",
      "line-height": "120%",
      "letter-spacing": "-2.16px",
    },
  ],
  "Title-20-Super-Normal": [
    {
      "font-size": "28px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.84px",
    },
    {
      "font-size": "36px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-1.08px",
    },
    {
      "font-size": "48px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-1.44px",
    },
  ],
  "Title-20-Super-Italic": [
    {
      "font-size": "28px",
      "font-style": "italic",
      "line-height": "120%",
      "letter-spacing": "-.84px",
    },
    {
      "font-size": "36px",
      "font-style": "italic",
      "line-height": "120%",
      "letter-spacing": "-1.08px",
    },
    {
      "font-size": "48px",
      "font-style": "italic",
      "line-height": "120%",
      "letter-spacing": "-1.44px",
    },
  ],
  "Title-10-Super-Normal": [
    {
      "font-size": "18px",
      "line-height": "130%",
      "font-style": "normal",
      "letter-spacing": "-0.54px",
    },
    {
      "font-size": "22px",
      "line-height": "130%",
      "font-style": "normal",
      "letter-spacing": "-.66px",
    },
    {
      "font-size": "28px",
      "line-height": "130%",
      "font-style": "normal",
      "letter-spacing": "-0.84px",
    },
  ],
  "Title-10-Super-Italic": [
    {
      "font-size": "18px",
      "line-height": "130%",
      "font-style": "italic",
      "letter-spacing": "-0.54px",
    },
    {
      "font-size": "22px",
      "line-height": "130%",
      "font-style": "italic",
      "letter-spacing": "-.66px",
    },
    {
      "font-size": "28px",
      "line-height": "130%",
      "font-style": "italic",
      "letter-spacing": "-0.84px",
    },
  ],
  "Subtitle-20-Dia-Normal": [
    {
      "font-size": "24px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-0.48px",
    },
    {
      "font-size": "26px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.52px",
    },
    {
      "font-size": "34px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.68px",
    },
  ],
  "Subtitle-20-Dia-Strong": [
    {
      "font-size": "24px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-0.48px",
    },
    {
      "font-size": "26px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.52px",
    },
    {
      "font-size": "34px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.68px",
    },
  ],
  "Subtitle-10-Dia-Normal": [
    {
      "font-size": "18px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-0.36px",
    },
    {
      "font-size": "22px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.44px",
    },
    {
      "font-size": "28px",
      "font-style": "normal",
      "line-height": "120%",
      "letter-spacing": "-.56px",
    },
  ],
  "Body-30-Dia-Normal": [
    {
      "font-size": "16px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.16px",
    },
    {
      "font-size": "18px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.18px",
    },
    {
      "font-size": "22px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.22px",
    },
  ],
  "Body-30-Dia-Strong": [
    {
      "font-size": "16px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.16px",
    },
    {
      "font-size": "18px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.18px",
    },
    {
      "font-size": "22px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.22px",
    },
  ],
  "Body-20-Dia-Normal": [
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "16px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.16px",
    },
  ],
  "Body-20-Dia-Strong": [
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "16px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-0.16px",
    },
  ],
  "Body-10-Dia-Normal": [
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.14px",
    },
  ],
  "Body-10-Dia-Strong": [
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.14px",
    },
  ],
  "Caption-10-Dia-Uppercase": [
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": ".12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": ".12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": ".12px",
    },
  ],
  "Caption-10-Dia-Normal": [
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "140%",
      "letter-spacing": "-.12px",
    },
  ],
  "CTA-20-Dia-Normal": [
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.14px",
    },
    {
      "font-size": "16px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.16px",
    },
  ],
  "CTA-10-Dia-Normal": [
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "12px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.12px",
    },
    {
      "font-size": "14px",
      "font-style": "normal",
      "line-height": "130%",
      "letter-spacing": "-0.14px",
    },
  ],
}

module.exports = { novaFonts }
