import React, { useState } from "react"

const defaultState = {
  isLoggedIn: false,
  atcData: null,
  activeOption: 0,
  site: null
}

const UserContext = React.createContext(defaultState)
  
const UserProvider = (props) => {

  const { children } = props

  const [customer, setCustomer] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [activeOption, setActiveOption] = useState(0)
  const [atcData, setAtcData] = useState(null)
  const [site, setSite] = useState(null)
  const [upsellData, setUpsellData] = useState(null)
  const [isCanceledSub, setIsCanceledSub] = useState(false)
  const [subGender, setSubGender] = useState(null)
  // const [failedSub, setFailedSub] = useState(null)

  return (
    <UserContext.Provider
      value={{
        isLoggedIn,
        activeOption,
        setActiveOption,
        atcData,
        setAtcData,
        site,
        setSite,
        upsellData,
        setUpsellData,
        customer,
        isCanceledSub,
        setIsCanceledSub,
        subGender,
        setSubGender,
        // failedSub,
        // setFailedSub,
        logUserOut: () => {
          setIsLoggedIn(false)
          setCustomer(null)
        },
        logUserIn: (c=null) => {
          setIsLoggedIn(true)
          setCustomer(c)
        }
      }}
    >
      {children}
    </UserContext.Provider>
  )

}

export default UserContext

export { UserProvider }